<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-map-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Visualizar setores para a setorização: {{ setorizacao.nome }} ({{
                numSetores
              }}
              setores)
            </div>
          </template>
          <v-row
            align="center"
            no-gutters
            class="ml-0 mt-3"
          >
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
                class="py-1 px-1"
              >
                <strong>Forma de setorização:</strong>
                {{ setorizacao.algoritmo.nome }} -
                {{ setorizacao.algoritmo.descricao }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            align="center"
            no-gutters
            class="ml-0"
          >
            <v-col cols="12">
              <div id="visualizar-mapa-setores"></div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mapaSetorizacaoMixins from '@/components/analises-setorizadas/mixins/mapaSetorizacaoMixins';

export default {
  mixins: [mapaSetorizacaoMixins],

  data() {
    return {
      setorizacao: {
        nome: '',
        algoritmo: {
          nome: '',
          descricao: ''
        }
      }
    };
  },

  mounted() {
    this.configuraMapa(8);
    this.getSetorizacao(this.$route.params.id);
  }
};
</script>

<style>
#visualizar-mapa-setores {
  height: 70vh;
  z-index: 0;
}
</style>
