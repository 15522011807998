import L from 'leaflet';
import MapboxConfig from '@/config/MapboxConfig';
import mapbox from '@/config/Mapbox';
import mapFeaturesStylesMixins from '@/mixins/mapFeaturesStylesMixins';
import GeoServerService from '@/services/GeoServerService';
import SetorizacoesService from '@/services/SetorizacoesService';
import geocoderMixins from '@/mixins/leaflet/geocoderMixins';
import browserPrintMixins from '@/mixins/leaflet/browserPrintMixins';
import scaleMixins from '@/mixins/leaflet/scaleMixins';
import filtersMixins from '@/mixins/geoserver/filtersMixins';

const mapboxConfig = new MapboxConfig(mapbox.access_token, mapbox.style);

export default {
  mixins: [
    mapFeaturesStylesMixins,
    geocoderMixins,
    browserPrintMixins,
    scaleMixins,
    filtersMixins
  ],

  data() {
    return {
      setorizacao: {
        nome: '',
        algoritmo: {
          nome: '',
          descricao: ''
        }
      },
      setores: [],
      mapaId: 'visualizar-mapa-setores',
      map: null,
      layers: [],
      layerControl: null,
      layerControlMaxLayers: 30
    };
  },

  computed: {
    numSetores() {
      return this.setores.length;
    },

    companyId() {
      return this.$store.getters.getCompanyId;
    },

    geoserverLayer() {
      return 'view_arat';
    },

    geoserverFields() {
      return 'geom';
    },

    geoserverCqlFilter() {
      if (
        this.companyId &&
        this.setorizacao &&
        this.setorizacao.mes &&
        this.setorizacao.versao
      ) {
        return this.getBasicCqlFilter(
          this.companyId,
          this.setorizacao.mes,
          this.setorizacao.versao
        );
      }
      return null;
    },

    geoserverOgcFilter() {
      if (
        this.companyId &&
        this.setorizacao &&
        this.setorizacao.mes &&
        this.setorizacao.versao
      ) {
        return this.getBasicOgcFilter(
          this.companyId,
          this.setorizacao.mes,
          this.setorizacao.versao
        );
      }
      return null;
    }
  },

  methods: {
    configuraMapa(zoom) {
      this.map = L.map(this.mapaId, {
        fullscreenControl: true,
        loadingControl: true,
        layers: [mapboxConfig.getDefaultLayer()],
        zoom
      });

      this.layerControl = L.control.layers(mapboxConfig.getBaseLayers(), null, {
        sortLayers: true
      });
      this.layerControl.addTo(this.map);

      this.createScaleControl(L).addTo(this.map);
      this.createGeocoderControl(L, this.map).addTo(this.map);
      this.createBrowserPrintControl(L, 'Mapa Setorização').addTo(this.map);

      const [longitude, latitude] =
        this.$store.getters.getCompanyCapitalCoordinates;

      this.map.setView([latitude, longitude], zoom);
    },

    getSetorizacao(id) {
      SetorizacoesService.getSetorizacao(id)
        .then((response) => (this.setorizacao = response.data.data))
        .catch(() =>
          this.$toast.error('Erro ao recuperar a setorização.', '', {
            position: 'topRight'
          })
        );
    },

    getSetores(id) {
      SetorizacoesService.getSetores(id)
        .then((response) => (this.setores = response.data))
        .catch(() =>
          this.$toast.error('Erro ao recuperar os setores criados.', '', {
            position: 'topRight'
          })
        );
    },

    getDadosAreaConcessao() {
      this.map.spin(true);

      return GeoServerService.getDadosLayer(
        this.geoserverLayer,
        this.geoserverFields,
        this.isCqlFilter() ? this.geoserverCqlFilter : this.geoserverOgcFilter,
        '', // bbox
        5000, // maxFeatures
        'application/json', // outputFormat
        this.getFilterType()
      );
    },

    async centralizaMapa() {
      const response = await this.getDadosAreaConcessao();
      const geojson = response.data;

      let aratLayer = L.geoJSON(geojson, {
        style: () => {
          const color = '#EF9A9A';
          const fillColor = '#FFCDD2';
          const opacity = this.getMapFeatureOpacity;
          const fillOpacity = 0.1;

          return {
            color,
            fillColor,
            opacity,
            fillOpacity
          };
        }
      });
      this.map.fitBounds(aratLayer.getBounds());
      aratLayer.addTo(this.map);

      this.layerControl.addOverlay(aratLayer, 'ARAT');

      setTimeout(() => {
        this.getSetores(this.setorizacao.id);
      }, 1000);
    },

    removeLayers() {
      this.layers.forEach((layer) => {
        this.map.removeLayer(layer);
        this.layerControl.removeLayer(layer);
      });
      this.layers = [];
    },

    plotaSetores() {
      const color = this.getMapFeatureColor;
      const fillColor = this.getMapFeatureFillColor;
      const opacity = this.getMapFeatureOpacity;
      const fillOpacity = this.getMapFeatureFillOpacity;
      const weight = this.getMapFeatureWeight;

      this.removeLayers();

      this.setores.forEach((setor) => {
        let layer = L.geoJSON(JSON.parse(setor.geojson), {
          style: () => {
            return {
              color,
              fillColor,
              opacity,
              fillOpacity,
              weight
            };
          }
        });

        let msgPopup = this.msgPopup(setor);
        if (msgPopup) layer.bindPopup(msgPopup);

        layer.addTo(this.map);
        this.layers.push(layer);

        if (this.numSetores <= this.layerControlMaxLayers) {
          this.layerControl.addOverlay(layer, setor.nome);
        }
      });

      this.map.fitBounds(L.featureGroup(this.layers).getBounds());

      this.map.spin(false);
    },

    msgPopup(setor) {
      let msg = `<h4>${setor.nome}</h4>`;

      const atributos = JSON.parse(setor.atributos);
      Object.keys(atributos).forEach((atributo) => {
        let dado = atributos[atributo];
        if (dado === '' || dado === null) return;
        msg += `<b>${atributo}:</b> ${dado}<br>`;
      });

      return msg;
    }
  },

  watch: {
    setores() {
      this.plotaSetores();
    },

    geoserverCqlFilter() {
      this.centralizaMapa();
    }
  }
};
